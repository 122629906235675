import React from "react";
import CustomStyledH2Heading from "../../../common/components/customStyledH2Heading/CustomStyledH2Heading";
import StyledLgParagraph from "../../../common/components/styledLgParagraph/StyledLgParagraph";
import Card from "./cardsSection/Card";

const ary = [
  { id: 1, image: "/assets/men.png" },
  {
    id: 2,
    title: "Water Features",
    desc: "Water features can become a hassle. Stop scrubbing algae or fixing pumps, let a pro handle it. Whether it’s routine care or a full installation, we connect you with experts who keep your water feature as stunning as the day it was built.",
    text: "See more",
  },
  { id: 1, image: "/assets/veg.png" },
  {
    id: 2,
    title: "Lawn Care",
    desc: "No shows, untrustworthy landscapers, inconsistency, and pricing headaches can become frustrating. With the Lawnolu app, skip the hassle and connect with top-rated professionals who show up on time and do the job right.",
    text: "See more",
  },
  { id: 1, image: "/assets/grassimg.png" },
  {
    id: 2,
    title: "Irrigation Systems",
    desc: "Clogged nozzles, leaks, and uneven watering turn well-installed systems into a money pit. Whether it’s a new install, maintenance, or quick fix, the professionals keep things running so you never have to drag a hose around again.",
    text: "See more",
  },
];

const GallerySection = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-[90%] flex flex-col justify-center items-center gap-8 md:gap-14 py-6 md:py-10 lg:py-14">
        <div className="md:w-4/5 lg:w-3/5 flex flex-col justify-center items-center gap-4">
          <div className="text-center">
            <CustomStyledH2Heading
              children={"The Beauty of "}
              fontColor={"text-dark-green font-semibold"}
              children2={"Expert Lawn Care"}
              fontColor2={"text-light-green font-semibold"}
            />
          </div>
          <div className="text-center">
            <StyledLgParagraph
              children={
                "With Lawnolu, scheduling is effortless, repeat services are a breeze, and real-time updates keep you in the loop. Share lawn photos, set recurring appointments, and enjoy a yard that’s always taken care of without the guesswork."
              }
              fontColor={"text-dark-gray font-medium"}
            />
          </div>
        </div>
        {/* card section */}
        <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 xl:gap-6">
          {ary.map((items, index) =>
            index % 2 === 0 ? (
              <Card key={index} image={items.image} />
            ) : (
              <Card key={index} content={items} />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default GallerySection;
