import React from "react";
import StyledMdParagraph from "../../../common/components/styledMdParagraph/StyledMdParagraph";

const FooterButton = ({ onSubmit }) => {
  return (
    <div className="w-full flex flex-col justify-center items-start gap-1">
      <div className="w-full">
        <input
          className="w-[100%]  outline-none rounded-full px-3 py-3 "
          type="text"
          placeholder="Enter your email "
        />
      </div>
      <div className="w-[100%]">
        <button
          onClick={onSubmit}
          className="w-[100%]  flex justify-center items-center bg-light-green  rounded-full px-2 py-3  "
        >
          <StyledMdParagraph
            children={"Subscribe"}
            fontColor={"text-white font-semibold !font-bricolage"}
          />
        </button>
      </div>
    </div>
  );
};

export default FooterButton;
