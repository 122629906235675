import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

export const API = axios.create({
  baseURL,
});

export const callApi = async (
  url,
  method = "POST",
  payload = {},
  params = {}
) => {
  try {
    const response = await API({
      url: url.startsWith("/") ? url.substring(1) : url,
      method,
      data: payload,
      params,
    });

    return response?.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
