import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import StyledMdParagraph from "../../../common/components/styledMdParagraph/StyledMdParagraph";
import { toast } from "react-toastify";
import { callApi } from "../../../api/api";

const validationSchema = Yup.object({
  fullName: Yup.string().required("Full Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  telephone: Yup.string().required("Telephone is required"),
  service: Yup.string().required("Service is required"),
  message: Yup.string().required("Message is required"),
});

const ContactForm = () => {
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      telephone: "",
      service: "",
      message: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await callApi(`/contact-us`, "POST", {
          name: values.fullName,
          email: values.email,
          phone: values.telephone,
          service: values.service,
          message: values.message,
        });

        if (!response.success) {
          throw new Error(response?.message || "Failed to submit the form");
        }
        toast.success(response?.message);

        resetForm();
      } catch (error) {
        if (error) {
          toast.error(error?.message);
        } else {
          toast.error("Failed to submit the form. Please try again.");
        }
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col items-start justify-center w-full gap-8 px-10 text-white bg-light-green py-14 rounded-2xl"
    >
      <div className="flex flex-col items-center justify-between w-full gap-8 xl:flex-row">
        <div className="w-full">
          <input
            type="text"
            name="fullName"
            placeholder="Full name"
            className="w-full input-green pb-2 outline-none placeholder:text-white"
            value={formik.values.fullName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.fullName && formik.errors.fullName && (
            <div className="text-red-600 text-sm mt-1">
              {formik.errors.fullName}
            </div>
          )}
        </div>

        <div className="w-full">
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="w-full input-green pb-2 outline-none placeholder:text-white"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="text-red-600 text-sm mt-1">
              {formik.errors.email}
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col items-center justify-between w-full gap-8 xl:flex-row">
        <div className="w-full">
          <input
            type="text"
            name="telephone"
            placeholder="Telephone"
            className="w-full input-green pb-2 outline-none placeholder:text-white"
            value={formik.values.telephone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.telephone && formik.errors.telephone && (
            <div className="text-red-600 text-sm mt-1">
              {formik.errors.telephone}
            </div>
          )}
        </div>

        <div className="w-full">
          <input
            type="text"
            name="service"
            placeholder="Service"
            className="w-full input-green pb-2 outline-none placeholder:text-white"
            value={formik.values.service}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.service && formik.errors.service && (
            <div className="text-red-600 text-sm mt-1">
              {formik.errors.service}
            </div>
          )}
        </div>
      </div>

      <div className="w-full">
        <textarea
          name="message"
          placeholder="Message"
          className="w-full bg-transparent border-b outline-none placeholder:text-white md:w-full pb-14 overflow-x-hidden overflow-y-auto no-scrollbar"
          style={{ resize: "none", scrollbarWidth: "none" }}
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.message && formik.errors.message && (
          <div className="text-red-600 text-sm mt-1">
            {formik.errors.message}
          </div>
        )}
      </div>

      <div className="w-full">
        <button
          type="submit"
          disabled={!formik.isValid || !formik.dirty}
          className={`w-full px-10 py-4 duration-500 rounded-full md:py-4 md:px-20 
    ${
      !formik.isValid || !formik.dirty
        ? "bg-gray-200 cursor-not-allowed text-white"
        : "bg-white text-light-green hover:bg-dark-green hover:text-white"
    }
  `}
        >
          <StyledMdParagraph
            children={"Get Started"}
            fontColor={"font-medium !font-bricolage"}
          />
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
