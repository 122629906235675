import React from "react";
import PrivacyPolicy from "../../sections/PrivacyPolicySection/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <PrivacyPolicy />
    </div>
  );
};

export default PrivacyPolicyPage;
