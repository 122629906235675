import React, { useState } from "react";
import StyledLgParagraph from "../../../common/components/styledLgParagraph/StyledLgParagraph";
import StyledH5Heading from "../../../common/components/styledH5Heading/StyledH5Heading";
import StyledMdParagraph from "../../../common/components/styledMdParagraph/StyledMdParagraph";
import { Link, NavLink } from "react-router-dom";
import FooterButton from "../common/FooterButton";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { callApi } from "../../../api/api";

const Footer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const subscribe = async () => {
    try {
      const response = await callApi(
        `/contact-us/news-letter-subscribe`,
        "POST",
        { email }
      );

      if (response?.success) {
        toast.success(response?.message);
        setEmail("");
      } else {
        toast.error(response?.message);
        setEmail("");
      }
    } catch (error) {
      const errorMessage = error?.message || "Something went wrong";
      toast.error(errorMessage);
      setEmail("");
    }
  };

  const icon = [
    { image: "/assets/twitter.png", link: "https://x.com/lawnolu" },
    { image: "/assets/insta.png", link: "https://www.instagram.com/lawnolu" },
    {
      image: "/assets/fb.png",
      link: "https://www.facebook.com/people/Lawnolu/100068881707289/",
    },
    { image: "/assets/vimo.png", link: "https://vimeo.com/lawnolu" },
  ];
  const nav = [
    { link: "Why Choose Lawnolu", href: "#why-choose-lawnolu" },
    { link: "Our Service", href: "#services" },
  ];
  const footerlinks = [
    { link: "Privacy Policy", href: "/privacy-policy#privacy-section" },
    { link: "Terms & Condition", href: "/terms-and-conditions#terms-section" },
  ];

  const scroll = (event, sectionId) => {
    event.preventDefault();
    if (pathname === "/") {
      const section = document.querySelector(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/");
    }
  };
  const contact = [
    {
      image: "/assets/vector1.png",
      desc: "info@lawnolu.com",
      href: "mailto:info@lawnolu.com",
    },
    {
      image: "/assets/vector2.png",
      desc: "Riverside Building, County Hall, London SE1 7PB, United Kingdom",
      href: "https://www.google.com/maps/place/Riverside Building, County Hall, London SE1 7PB, United Kingdom}",
    },
    {
      image: "/assets/vector3.png",
      desc: "451-484-5939",
      href: "tel:451-484-5939",
    },
  ];

  return (
    <div className="w-[100%] flex justify-center items-center bg-dark-green">
      <div className="w-[90%] grid grid-cols-1 md:grid md:grid-cols-2 lg:flex flex-row justify-center items-start gap-8  py-14 lg:py-24 ">
        <div className=" w-[100%] flex flex-col justify-center items-start gap-6">
          <div>
            <img
              className="w-[150px] h-[35px] md:w-[180px] md:h-[40px] xl:h-full xl:w-full"
              src="/assets/logo.png"
              alt=""
            />
          </div>
          <div className="w-[70%]">
            <StyledLgParagraph
              children={
                "Lawnolu is the ultimate mobile solution for homeowners and businesses seeking reliable lawn care services."
              }
              fontColor={"text-white font-medium"}
            />
          </div>
          <div className="flex items-center justify-center gap-3 ">
            {icon.map((items, index) => (
              <div className="cursor-pointer ">
                <Link to={items.link} target="_blank">
                  <img
                    className="transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-105"
                    src={items.image}
                    alt=""
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>

        <div className="w-[55%] flex flex-col justify-center items-start gap-4 md:gap-6 ">
          <div>
            <StyledH5Heading
              children={"Quick Links"}
              fontColor={"text-white font-semibold"}
            />
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col items-start justify-center gap-4">
              {nav.map((items, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center list-none "
                >
                  <NavLink
                    to={items.href}
                    onClick={(e) => scroll(e, items?.href)}
                  >
                    {""}
                    <li className="">
                      <StyledLgParagraph
                        children={items.link}
                        fontColor={
                          "text-white hover:text-light-green active:text-orange font-semibold"
                        }
                      />
                    </li>
                  </NavLink>
                </div>
              ))}
            </div>
            <div className="flex flex-col items-start justify-center gap-4 text-nowrap">
              {footerlinks.map((items, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center list-none "
                >
                  <Link to={items.href}>
                    <li className="">
                      <StyledLgParagraph
                        children={items.link}
                        fontColor={
                          "text-white hover:text-light-green active:text-orange font-semibold"
                        }
                      />
                    </li>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="w-[80%] flex flex-col justify-center items-start gap-4 md:gap-6">
          <div className="">
            <StyledH5Heading
              children={"Contact Us"}
              fontColor={"text-white font-semibold"}
            />
          </div>
          <div className="flex flex-col items-start justify-center gap-4 ">
            {contact.map((items, index) => (
              <div
                key={index}
                className="flex items-center justify-center gap-2 "
              >
                <div className="">
                  <img className="w-[16px] h-[16px]" src={items.image} alt="" />
                </div>
                <div className="w-[100%]">
                  <StyledLgParagraph
                    fontColor={
                      "text-white font-medium hover:text-light-green active:text-orange"
                    }
                  >
                    <Link to={items.href}>{items.desc}</Link>
                  </StyledLgParagraph>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-start justify-center gap-4 md:gap-6">
          <div>
            <StyledH5Heading
              children={"Newsletter"}
              fontColor={"text-white font-semibold"}
            />
          </div>
          <div className="hidden h-[48px] sm:flex justify-center items-center">
            <input
              className="h-[100%] outline-none rounded-l-full px-2 py-3 md:p-4 bg-white input-white"
              type="text"
              value={email}
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={subscribe}
              className="h-[100%] flex justify-center items-center bg-light-green  rounded-r-full px-2 py-3 md:p-4 "
            >
              <StyledMdParagraph
                children={"Subscribe"}
                fontColor={"text-white font-semibold !font-bricolage"}
              />
            </button>
          </div>
          {/* button for mobile */}
          <div className="flex w-full sm:hidden">
            <FooterButton onSubmit={subscribe} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
