import React from 'react';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import loaderAnimation from './loaderAnimation.json';

function Loader({ text }) {
    return (
        <div className="fixed top-0 left-0 w-screen h-screen flex flex-col justify-center items-center bg-black bg-opacity-75 z-50">
            <div className="flex flex-col relative items-center justify-center mt-[-25%]">
                <div className='w-96'>
                    <Lottie animationData={loaderAnimation} />
                </div>
                <p className="text-white text-lg">
                    {text ?? 'Loading...'}
                </p>
            </div>
        </div>
    );
}

Loader.propTypes = {
    text: PropTypes.string,
};

export default Loader;
