import React from "react";
import TermsAndConditions from "../../sections/TermsAndConditionSection/TermsAndConditions";

const TermsAndConditionPage = () => {
  return (
    <div>
      <TermsAndConditions />
    </div>
  );
};

export default TermsAndConditionPage;
