import React, { useState } from "react";
import CustomStyledH2Heading from "../../../common/components/customStyledH2Heading/CustomStyledH2Heading";
import FaqCard from "./FaqCard";

const FaqSection = () => {
  const [activeTab, setActiveTab] = useState("business");

  const business = [
    {
      image: "/assets/QM.png",
      title: "Is the Lawnolu app really free to use?",
      desc: "Yes! Lawnolu is completely free to download and use, with no hidden fees.",
    },
    {
      image: "/assets/QM.png",
      title: "Is there a contract for businesses?",
      desc: "No, there is no contract required. You can sign up, list your business, and cancel anytime—no obligations.",
    },
    {
      image: "/assets/QM.png",
      title: "Can clients book services directly through the app?",
      desc: "Yes! Clients can book your services directly through the app, call you, or send an email once your business profile is set up.",
    },
    {
      image: "/assets/QM.png",
      title: "Can customers leave reviews for my business?",
      desc: "Yes! Clients can leave reviews and ratings for your services, helping you build credibility and attract more customers.",
    },
    {
      image: "/assets/QM.png",
      title: "Can my clients share my Lawnolu page on social media?",
      desc: "Yes! With a single click, clients can share your business profile on all major social media platforms, helping you gain more exposure.",
    },
    {
      image: "/assets/QM.png",
      title: "Can my business accept payments through the app?",
      desc: "Yes! You can charge clients and receive payments securely through the app, making transactions seamless and hassle-free.",
    },
    {
      image: "/assets/QM.png",
      title: "Can businesses assign multiple workers within the app?",
      desc: "Yes! Lawnolu allows businesses to assign multiple workers to different jobs, ensuring efficient scheduling and team management.",
    },
    {
      image: "/assets/QM.png",
      title: "What if I have additional questions or need support?",
      desc: "We’re here to help! Our 24/7 chat support is available to assist with any questions or concerns.",
    },
  ];
  const customer = [
    {
      image: "/assets/QM.png",
      title: "How easy is Lawnolu to use?",
      desc: "Lawnolu is designed for simplicity. Just enter your zip code, browse service providers, and book instantly. If you have any questions, our 24/7 chat support is available to help.",
    },
    {
      image: "/assets/QM.png",
      title:
        "Can customers pay lawn care professionals directly through the app?",
      desc: "Yes! Clients can make payments directly within the app, making a smooth, secure, and convenient experience with no hidden fees.",
    },
    {
      image: "/assets/QM.png",
      title:
        "Can homeowners and businesses manage multiple properties on Lawnolu?",
      desc: "Yes! Homeowners can manage multiple properties within their account, making it easy to schedule and track lawn care services for different locations.",
    },
    {
      image: "/assets/QM.png",
      title: "Does Lawnolu provide real-time tracking for services?",
      desc: "Yes! Clients can track their service provider in real-time, so they know exactly when help is on the way.",
    },
    {
      image: "/assets/QM.png",
      title: "Where can I watch a video about how Lawnolu works?",
      desc: (
        <>
          Check out our video here:{" "}
          <a
            href="https://vimeo.com/657649501?turnstile=0.Yy-_6LIaycjhQr4cVYfbLFYg3SiqLcc388FqyYLFs[…]2d5f6a4a433b5bf88e7099dcb6f88d84d8625cbcdde68179bf77d02b4fd6e615"
            target="_blank"
            rel="noopener noreferrer"
            className="text-light-green font-medium underline"
          >
            Lawnolu Video
          </a>
        </>
      ),
    },

    {
      image: "/assets/QM.png",
      title: "How often should I mow my lawn?",
      desc: "Mowing once a week is the general rule, but it depends on the grass type, weather, and season. In colder months, mowing may only be needed once every month or two, while in summer, mowing every week is recommended.",
    },
    {
      image: "/assets/QM.png",
      title: "How much do most lawn care companies charge?",
      desc: "Lawn care services typically range from $30 to $65 per hour or about $100 or more per visit. However, the cost varies based on lawn size, service type, and location.",
    },
    {
      image: "/assets/QM.png",
      title: "When should I start lawn care for the season?",
      desc: "Lawn care should begin in early spring when temperatures warm up, and grass starts growing. This is usually between late February and March, depending on your region's climate.",
    },
    {
      image: "/assets/QM.png",
      title: "Why is lawn care important?",
      desc: "Proper lawn care improves air quality, provides oxygen, reduces noise pollution, and enhances curb appeal. A well-maintained lawn also prevents weeds, lawn diseases, and soil degradation, adding value to your home. ",
    },
    {
      image: "/assets/QM.png",
      title: "Do you offer eco-friendly lawn services?",
      desc: "Yes! Businesses on Lawnolu may offer organic lawn and landscape care, including safer, non-toxic weed control for families and pets nationwide.",
    },
    {
      image: "/assets/QM.png",
      title: "Are lawn care services worth it?",
      desc: "Yes! Professional lawn care saves time, prevents costly mistakes, and ensures a healthier, greener lawn free from weeds, grubs, and other issues.",
    },
    {
      image: "/assets/QM.png",
      title: "How often should I treat my lawn?",
      desc: "A minimum of once a year is recommended, but for the best results, feeding and treating your lawn at least four times a year promotes healthier, greener grass.",
    },
  ];

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="w-[90%] flex flex-col justify-center items-center gap-6 py-8 lg:py-14">
        <div className="w-full flex flex-col justify-center items-center gap-4">
          <div className="text-center xl:leading-[72px]">
            <CustomStyledH2Heading
              children={"Frequently Asked"}
              fontColor={"text-dark-green font-semibold"}
              children2={"Questions"}
              fontColor2={"text-light-green font-semibold"}
              breakline={true}
            />
          </div>
          <div className="flex justify-center items-center gap-4 md:w-[80%] xl:w-[60%]">
            <button
              className={`py-2 px-6 rounded-[30px] text-h6 font-bold transition-all duration-300 ${
                activeTab === "business"
                  ? "bg-light-green text-white"
                  : "bg-white text-light-green border border-light-green"
              }`}
              onClick={() => setActiveTab("business")}
            >
              For Businesses
            </button>
            <button
              className={`py-2 px-6 rounded-[30px] text-h6 font-bold transition-all duration-300 ${
                activeTab === "customer"
                  ? "bg-light-green text-white"
                  : "bg-white text-light-green border border-light-green"
              }`}
              onClick={() => setActiveTab("customer")}
            >
              For Customers
            </button>
          </div>
        </div>
        {/* card section */}
        <div
          className="w-full flex flex-col justify-center gap-4 md:grid md:grid-cols-2 items-stretch transition-opacity duration-500 ease-in-out"
          key={activeTab}
        >
          {(activeTab === "business" ? business : customer).map(
            (item, index) => (
              <div
                key={index}
                className="px-4 py-6 xl:py-10 xl:px-6 border-2 md:border-none rounded-2xl shadow-md"
              >
                <FaqCard item={item} />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
