import React from "react";

import StyledLgParagraph from "../../../common/components/styledLgParagraph/StyledLgParagraph";
import CustomStyledH2Heading from "../../../common/components/customStyledH2Heading/CustomStyledH2Heading";
import StyledH3Heading from "../../../common/components/styledH3Heading/StyledH3Heading";

const AboutUsSection = () => {
  const ary = [
    { title: "13", sign: "+", paragraph: "Years Experience" },
    { title: "13K", sign: "+", paragraph: "Lawns Completed" },
    { title: "7K", sign: "+", paragraph: "Satisfied Clients" },
    { title: "12K", sign: "+", paragraph: "Local Team Members" },
  ];

  return (
    <section id="about-us" className="w-[100%]">
      <div className="w-[90%] mx-auto flex flex-col justify-center items-center gap-10 py-14">
        <div className=" w-[85%] text-center flex flex-col justify-center items-center gap-4 ">
          <div className=" w-full font-semibold leading-8 md:leading-10 xl:leading-[72px]">
            <CustomStyledH2Heading
              children={"Your Hassle-Free Lawn"}
              children2={"Care Starts Here"}
              fontColor={"text-dark-green"}
              fontColor2={"text-light-green"}
              breakline={true}
            />
          </div>
          <div className="w-full md:w-[70%] leading-6">
            <StyledLgParagraph
              children={
                "Lawnolu is more than just a booking app, it’s lawn care without the guesswork. See your lawn pro’s real-time location, chat instantly, and stay in the loop from start to finish. Lawn care should be simple, transparent, and stress free because great service should never leave you guessing."
              }
              fontColor={"text-dark-gray font-medium"}
            />
          </div>
        </div>

        <div className="w-full rounded-2xl overflow-hidden flex flex-col items-center">
          {/* Responsive Image */}
          <img
            className="w-full h-36 sm:h-48 md:h-full object-center object-cover"
            src="/assets/grass.png"
            alt="Grass"
          />
          <div className="w-full bg-dark-green p-4 md:p-6 overflow-auto">
            {/* Card Container */}
            <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-8">
              {ary.map((items, index) => (
                <div
                  key={index}
                  className="w-full flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0"
                >
                  {/* Text Container */}
                  <div className="w-full flex flex-col justify-center items-start sm:items-center">
                    {/* Title */}
                    <div className="cursor-pointer">
                      <StyledH3Heading
                        children={items.title}
                        fontColor="text-white font-semibold"
                        children2={items.sign}
                        fontColor2="text-light-orange"
                      />
                    </div>
                    {/* Paragraph */}
                    <div className="cursor-pointer md:whitespace-nowrap">
                      <StyledLgParagraph
                        children={items.paragraph}
                        fontColor="text-white font-medium"
                      />
                    </div>
                  </div>
                  {/* Vertical Divider */}
                  {index < ary.length - 1 && (
                    <div className="hidden lg:block">
                      <hr className="w-[1px] h-16 bg-white border-0" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
