import React from "react";
import LandingSection from "../../sections/Home/LandingSection/LandingSection";
import AboutUsSection from "../../sections/Home/AboutUsSection/AboutUsSection";
import ChooseUsSection from "../../sections/Home/ChooseUsSection/ChooseUsSection";
import ServiceSection from "../../sections/Home/ServiceSection/ServiceSection";
import GallerySection from "../../sections/Home/GallerySection/GallerySection";
import TestimonialSection from "../../sections/Home/TestimonialSection/TestimonialSection";
import FaqSection from "../../sections/Home/FaqSection/FaqSection";
import ContactUsSection from "../../sections/Home/ContactUsSection/ContactUsSection";

const Homepage = () => {
  return (
    <div className="">
      <LandingSection />
      <AboutUsSection />
      <ChooseUsSection />
      <ServiceSection />
      {/* <PricingSection /> */}
      <GallerySection />
      <TestimonialSection />
      <FaqSection />
      {/* <BlogSection /> */}
      <ContactUsSection />
      {/* <PrivacyPolicy />
      <TermsAndConditions /> */}
    </div>
  );
};

export default Homepage;
