import React from "react";

const StyledLgParagraph = ({ children, children2, fontColor,fontColor2 }) => {
  return( <p className={`text-lg font-mulish ${fontColor}`}>{children}
  <span className={`${fontColor2}`}>{children2}</span></p>
  
  );
};
export default StyledLgParagraph;

