import React from "react";
import CustomStyledH2Heading from "../../common/components/customStyledH2Heading/CustomStyledH2Heading";
import StyledMdParagraph from "../../common/components/styledMdParagraph/StyledMdParagraph";
import DeleteForm from "../../components/layout/common/DeleteForm/DeleteForm";

const DeleteAccount = () => {
  return (
    <section
      id="delete-account"
      className="w-[100%] flex flex-col justify-center items-center"
    >
      <div className="w-[90%] flex flex-col items-center lg:flex lg:flex-row justify-between  gap-10 md:gap-12 lg:gap-0  py-14">
        <div className=" w-[100%] flex flex-col gap-2 justify-center items-start">
          <div>
            <CustomStyledH2Heading
              children={"Delete Account "}
              fontColor={"text-dark-green font-semibold"}
            />
          </div>
          <div>
            <StyledMdParagraph
              children={"Fill in the form below to remove your SOS account."}
              fontColor={"text-dark-gray font-semibold"}
            />
          </div>
        </div>
        {/* card */}
        <div className=" w-[100%]">
          <DeleteForm />
        </div>
      </div>
    </section>
  );
};

export default DeleteAccount;
