import React, { useEffect, useState } from 'react'
import StyledH5Heading from '../../common/components/styledH5Heading/StyledH5Heading'
import StyledLgParagraph from '../../common/components/styledLgParagraph/StyledLgParagraph'
import Loader from '../../components/loader/loader';

const BankRegistrationPage = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <div className='flex justify-center items-center w-full h-[90vh]'>
                <div className='flex flex-col justify-center items-center text-center gap-8 w-[90%] sm:w-[80%] md:w-[65%] lg:w-[50%] xl:w-[35%] bg-[#49977F] px-10 pt-[2rem] pb-[4rem] rounded-[22px]'>
                    <div>
                        <img src='/assets/verified.svg' alt='verified Icon' />
                    </div>
                    <div>
                        <StyledH5Heading fontColor='text-white font-extrabold mb-1'>
                            Bank Added Successfully
                        </StyledH5Heading>
                        <StyledLgParagraph fontColor='text-white font-medium'>
                            Your payout method has been successfully added. Return to the Lawn Olu app to withdraw your earnings.
                        </StyledLgParagraph>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BankRegistrationPage
