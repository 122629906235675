import { NavLink } from "react-router-dom";
import Button from "../common/Button/Button";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StyledLgParagraph from "../../../common/components/styledLgParagraph/StyledLgParagraph";
const MobileNav = () => {
  const nav = [
    { link: "Home", href: "#home" },
    { link: "About Us", href: "#about-us" },
    { link: "Our Service", href: "#services" },
    { link: "Contact Us", href: "#contact-us" },
  ];

  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showMenu]);

  const scroll = (event, sectionId) => {
    event.preventDefault();
    setShowMenu(false);
    if (pathname === "/") {
      const section = document.querySelector(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/", { state: { sectionId } });
    }
  };

  return (
    <div className="lg:hidden w-[90%]  flex justify-center items-center flex-col overflow-y-auto ">
      <div className="flex lg:hidden w-[100%] justify-between items-center py-2">
        {/* logo */}
        <div className="">
          <img
            className=" w-[150px] h-[35px]"
            src="/assets/logo.png"
            alt="logo-img"
          />
        </div>
        {/* toggle */}
        <div className="flex cursor-pointer ">
          {/* cross svg  */}
          <svg
            onClick={() => setShowMenu(false)}
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            className={`${showMenu === false ? "hidden" : "flex"}`}
          >
            <path
              d="M14.5871 12.5996L9.4875 7.5L14.5871 2.40043C15.1376 1.84986 15.1376 0.963491 14.5871 0.412925C14.0365 -0.137642 13.1501 -0.137642 12.5996 0.412925L7.5 5.5125L2.40043 0.412925C1.84986 -0.137642 0.963491 -0.137642 0.412925 0.412925C-0.137642 0.963491 -0.137642 1.84986 0.412925 2.40043L5.5125 7.5L0.412925 12.5996C-0.137642 13.1501 -0.137642 14.0365 0.412925 14.5871C0.963491 15.1376 1.84986 15.1376 2.40043 14.5871L7.5 9.4875L12.5996 14.5871C13.1501 15.1376 14.0365 15.1376 14.5871 14.5871C15.1337 14.0365 15.1337 13.1462 14.5871 12.5996Z"
              fill="#ffffff"
            />
          </svg>

          {/* hamburger svg  */}

          <svg
            onClick={() => setShowMenu(true)}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="15"
            viewBox="0 0 18 15"
            fill="none"
            className={`${showMenu === false ? "flex" : "hidden"}`}
          >
            <rect width="18" height="3" rx="1.5" fill="#FFFFFF" />
            <rect y="6" width="18" height="3" rx="1.5" fill="#FFFFFF" />
            <rect y="12" width="18" height="3" rx="1.5" fill="#FFFFFF" />
          </svg>
        </div>
      </div>

      <div
        className={` w-[100%]  bg-white h-[100vh] rounded-lg flex flex-col justify-center items-center ${
          showMenu === false ? "hidden" : "flex"
        }`}
      >
        <div className="flex flex-col items-center justify-center gap-6 py-4 text-black">
          <ul className="flex flex-col items-center justify-between gap-6 ">
            {nav.map((items, index) => (
              <div key={index} className="">
                <NavLink
                  to={items.href}
                  onClick={(e) => scroll(e, items?.href)}
                >
                  {""}
                  <li className="">
                    <StyledLgParagraph
                      children={items.link}
                      fontColor={
                        "text-black hover:text-light-green active:text-orange font-bold"
                      }
                    />
                  </li>
                </NavLink>
              </div>
            ))}
          </ul>
          <Button />
        </div>
      </div>
    </div>
  );
};
export default MobileNav;
