import React from "react";
import StyledH5Heading from "../../../common/components/styledH5Heading/StyledH5Heading";
import StyledLgParagraph from "../../../common/components/styledLgParagraph/StyledLgParagraph";

const Card = ({ item }) => {
  return (
    <div className="w-[100%] flex flex-col justify-center items-center xl:items-start gap-4 ">
      <div className="flex flex-col items-center   xl:flex xl:flex-row justify-center xl:items-start gap-2">
        <div>
          <img className="rounded-full" src={item.userImage} alt="" />
        </div>
        <div className="flex flex-col justify-center items-center xl:items-start gap-2">
          <div className="flex items-center justify-center gap-0.5">
            <StyledH5Heading
              children={item.title}
              fontColor={"text-dark-green font-semibold"}
            />
            {item.subTitle && (
              <StyledLgParagraph
                children={item.subTitle}
                fontColor={"text-dark-green font-semibold"}
              />
            )}
          </div>
          <div className="flex gap-1">
            {item.starImage.map((item, index) => (
              <img key={index} src={item.image} alt="" />
            ))}
          </div>
        </div>
      </div>
      <div className="text-center xl:text-start">
        <StyledLgParagraph
          children={item.desc}
          fontColor={"text-dark-gray font-medium"}
        />
      </div>
    </div>
  );
};

export default Card;
