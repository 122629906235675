import React from "react";
import CustomStyledH2Heading from "../../../common/components/customStyledH2Heading/CustomStyledH2Heading";
import StyledLgParagraph from "../../../common/components/styledLgParagraph/StyledLgParagraph";
import StyledH4Heading from "../../../common/components/styledH4Heading/StyledH4Heading";

const ChooseUsSection = () => {
  const ary = [
    {
      image: "/assets/tick.png",
      title: "Expertise You Can Trust",
      paragraph:
        "Lawnolu makes hiring trusted lawn care professionals easy. With verified experts, real customer reviews, and transparent pricing, you get reliable, high-quality lawn services with no surprises just great results.",
      text: "Read More...",
    },
    {
      image: "/assets/tick.png",
      title: "All In One Experience",
      paragraph:
        "Clients book services, businesses find clients, and workers are matched with jobs all in one place. Streamlined searches, secure payments, and instant messaging a complete solution for professionals and homeowners.",
      text: "Read More...",
    },
    {
      image: "/assets/tick.png",
      title: "Customer-Centric Approach",
      paragraph:
        "We make lawn care seamless, reliable, and stress-free and eliminate the guesswork with easy booking, real-time tracking, and direct communication, so you always know what to expect. Quality service, on your terms.",
      text: "Read More...",
    },
  ];

  return (
    <div
      id="why-choose-lawnolu"
      className="w-full flex flex-col justify-center items-center py-8 lg:py-14 bg-white"
    >
      <div className="w-[95%] flex flex-col lg:flex-row justify-center items-center gap-14">
        <div className="lg:w-1/2 w-[95%] flex flex-col justify-center items-center lg:items-start gap-6">
          <div className="text-center lg:text-left">
            <CustomStyledH2Heading
              children={"Why Choose "}
              children2={"Lawnolu?"}
              fontColor={"text-dark-green font-semibold"}
              fontColor2={"text-light-green font-semibold"}
            />
          </div>
          <div className="text-center lg:text-left">
            <StyledLgParagraph
              children={
                "Lawnolu brings modern convenience to lawn care, replacing uncertainty with peace of mind. It’s the smarter way to book dependable lawn care. With advanced search options, built-in tracking, and a network of trusted professionals, we create a more reliable experience for homeowners, businesses, and workers alike. Spend less time managing, and more time enjoying your lawn."
              }
              fontColor={"text-dark-gray font-medium"}
            />
          </div>
          <div className="md:w-full w-[95%] flex flex-col gap-4">
            {ary.map((items, index) => (
              <div
                key={index}
                className="flex flex-col md:flex-row items-center gap-4 p-4 border border-gray-300 lg:border-none rounded-2xl transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-300"
              >
                <div className="w-16 h-16 flex-shrink-0">
                  <img
                    src={items.image}
                    alt="tick-icon"
                    className="w-full h-full object-contain"
                  />
                </div>
                <div className="flex flex-col justify-center md:justify-start items-center md:items-start gap-2">
                  <StyledH4Heading
                    children={items.title}
                    fontColor={"text-dark-gray font-bold"}
                  />
                  <StyledLgParagraph
                    children={items.paragraph}
                    fontColor={"text-dark-gray font-medium"}
                  />
                  {/* <a href="#" className="text-light-green font-medium">{items.text}</a> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="md:w-[40%] w-full">
          <img
            className="w-full rounded-2xl object-cover"
            src="/assets/phone.svg"
            alt="phone"
          />
        </div>
      </div>
    </div>
  );
};

export default ChooseUsSection;
