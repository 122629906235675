import React from "react";
import BusinessRegistration from "../../sections/RegistrationSection/BusinessRegistration";


const BusinessRegistrationPage = () => {
    return (
        <div className="">
            <BusinessRegistration />
        </div>
    );
};

export default BusinessRegistrationPage;
