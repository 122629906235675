import React from "react";
import StyledLgParagraph from "../../../common/components/styledLgParagraph/StyledLgParagraph";
import StyledH4Heading from "../../../common/components/styledH4Heading/StyledH4Heading";

const FaqCard = ({ item }) => {
  return (
    <div className="w-[100%] flex justify-start items-start gap-4 ">
      <div className="">
        <img className="h-[24px] w-[28px]" src={item.image} alt="" />
      </div>
      <div className=" w-[100%] flex flex-col justify-center items-start gap-2">
        <div className=" w-[95%] ">
          <StyledH4Heading
            children={item.title}
            fontColor={"text-dark-green font-semibold"}
          />
        </div>
        <div className="w-[95%]">
          <StyledLgParagraph
            children={item.desc}
            fontColor={"text-dark-gray font medium"}
          />
        </div>
      </div>
    </div>
  );
};

export default FaqCard;
