import React from "react";
import CustomStyledH1Heading from "../../../common/components/customStyledH1Heading/CustomStyledH1Heading";
import StyledLgParagraph from "../../../common/components/styledLgParagraph/StyledLgParagraph";
import StyledH3Heading from "../../../common/components/styledH3Heading/StyledH3Heading";

const LandingSection = () => {
  return (
    <section className="w-[100%] flex flex-col-reverse md:flex md:flex-row lg:flex lg:flex-row justify-between items-center gap-6">
      <div className="w-[100%] self-stretch">
        <img className="w-full h-full" src="/assets/girl.png" alt="girl-img" />
      </div>

      <div className="w-[100%]  flex flex-col justify-center items-center  leading-none gap-10  xl:gap-20 p-4 lg:p-0  lg:pr-32">
        <div className=" flex flex-col items-center md:flex md:flex-col justify-between md:items-start gap-6">
          <div className="text-center md:text-start">
            <CustomStyledH1Heading
              children={"Your "}
              children2={"Lawn "}
              children3={"Our Passion"}
              fontColor={"text-dark-green "}
              fontColor2={"text-light-green"}
            />
          </div>
          <div className="text-center md:text-start leading-5 ">
            <StyledLgParagraph
              children={
                "Lawn care should be simple, reliable, and stress free. With Lawnolu, you can find trusted professionals, book services, track appointments, and make secure payments all in one easy-to-use app. Whether it’s your home, rental, or community space, expert lawn care is now just a tap away."
              }
              fontColor={"text-dark-gray font-medium"}
            />
          </div>
          <div className="w-[100%] cursor-pointer flex justify-center items-center  md:flex flex-row md:justify-start md:items-start gap-2">
            <img
              className="w-[150px] lg:w-[188px] "
              src="/assets/app-store.svg"
              alt="AppStore-img"
              onClick={() =>
                window.open("https://www.apple.com/app-store/", "_blank")
              }
            />
            <img
              className="w-[150px] lg:w-[188px] "
              src="/assets/play-store.svg"
              alt="AppStore-img"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/games?hl=en&gl=US&pli=1",
                  "_blank"
                )
              }
            />
          </div>
        </div>

        <div className="w-full flex flex-col justify-center items-center gap-5  flex-wrap    md:flex md:flex-row md:justify-start md:items-center">
          <div class="-space-x-2 overflow-hidden">
            <img
              class="inline-block h-14 w-14 rounded-full ring-2 ring-white"
              src="/assets/img1.png"
              alt=""
            />
            <img
              class="inline-block h-14 w-14 rounded-full ring-2 ring-white"
              src="/assets/img2.png"
              alt=""
            />
            <img
              class="inline-block h-14 w-14 rounded-full ring-2 ring-white"
              src="/assets/img3.png"
              alt=""
            />
            <img
              class="inline-block h-14 w-14 rounded-full ring-2 ring-white"
              src="/assets/img4.png"
              alt=""
            />
            <img
              class="inline-block h-14 w-14 rounded-full ring-2 ring-white"
              src="/assets/img5.png"
              alt=""
            />
          </div>

          <div className="flex flex-col justify-center items-center  md:items-start leading-none cursor-pointer">
            <StyledH3Heading children={"50K+"} fontColor={"text-orange"} />
            <StyledLgParagraph
              children={"Joined in already"}
              fontColor={"text-dark-gray font-medium"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingSection;
