import React from "react";
import StyledH5Heading from "../../../common/components/styledH5Heading/StyledH5Heading";
import StyledLgParagraph from "../../../common/components/styledLgParagraph/StyledLgParagraph";
import StyledMdParagraph from "../../../common/components/styledMdParagraph/StyledMdParagraph";

const Card = ({ item }) => {
  return (
    <div className="w-[100%] flex flex-col lg:flex lg:flex-row justify-between gap-4  items-center border-gray border-2 md:border-0 rounded-2xl p-6 md:py-2 md:px-4 lg:p-6 xl:py-10 xl:px-6">
      <div className=" w-[20%] md:w-[10%] lg:w-[15%] xl:w-[10%]">
        <img src={item.image} alt="icon" />
      </div>
      <div className=" w-[100%] flex flex-col justify-between items-center md:items-start gap-2">
        <div className="w-[95%] h-full flex flex-col lg:flex-row justify-between md:items-center">
          <div className="text-center md:text-start">
            <StyledH5Heading
              children={item.title}
              fontColor={"text-dark-green font-semibold"}
            />
          </div>
          <div className="text-center lg:self-end">
            <StyledMdParagraph
              // children={item.desc}
              fontColor={
                "text-light-green font-medium underline underline-offset-2"
              }
            />
          </div>
        </div>

        <div className="w-[100%] text-center md:text-start">
          <StyledLgParagraph
            children={item.para}
            fontColor={"text-dark-gray"}
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
