import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// ----------------------------------------------------------------------

export const Logo = () => {
  const logo = (
    <div className="flex flex-row justify-center mt-2">
      <img src="/assets/logoDark.svg" alt="Logo" width="200px" />
    </div>
  );

  return (
    <Link to="/" className="contents">
      {logo}
    </Link>
  );
};

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
