/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import StyledMdParagraph from "../../common/components/styledMdParagraph/StyledMdParagraph";
import StyledSmParagraph from "../../common/components/styledSmParagraph/StyledSmParagraph";
import StyledH4Heading from "../../common/components/styledH4Heading/StyledH4Heading";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/loader/loader";
import { StateContext } from "../../context/context";
import { callApi } from "../../api/api";

const BusinessRegistration = () => {
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    repeatPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    password: "",
    confirmPassword: "",
  });
  const [tokenExpiry, setTokenExpiry] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const [errors, setErrors] = useState({
    name: "",
    password: "",
    confirmPassword: "",
  });
  const { businessName, setBusinessName, setErrorMessage } = StateContext();

  const checkToken = async () => {
    try {
      setLoading(true);
      const response = await callApi(
        "/business/accept-worker-invitation",
        "POST",
        { token }
      );

      const { expired, newAccount, companyName } = response?.data || {};
      setTokenExpiry(token);
      setBusinessName(companyName);

      if (expired) {
        toast.error(response?.message);
        navigate("/link-expired");
      } else if (newAccount === false) {
        toast.success(response?.message);
        navigate("/registration-successfull");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setErrorMessage(error?.response?.data?.message ?? error.message);
      navigate("/link-expired");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      checkToken();
    }
  }, [token, navigate, setBusinessName]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (tokenExpiry === true) {
      navigate("/link-expired");
      return;
    }
    const newErrors = {
      name: formValues.name ? "" : "Name is required",
      password: formValues.password ? "" : "Password is required",
      confirmPassword: formValues.confirmPassword
        ? ""
        : "Confirm Password is required",
    };

    if (formValues.password !== formValues.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) return;

    setLoading(true);
    try {
      const response = await callApi(
        "/business/create-worker-profile",
        "POST",
        {
          userName: formValues.name,
          password: formValues.password,
          confirmPassword: formValues.confirmPassword,
          token: token,
        }
      );
      if (response?.status === 200) {
        if (response?.message === "Invitation link expired") {
          navigate("/link-expired");
          toast.error(response?.message);
        } else {
          navigate("/registration-successfull");
          toast.success(response?.message);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex justify-center w-full pt-10 pb-20">
          <div className="flex flex-col justify-center gap-5 w-[90%] sm:w-[80%] md:w-[65%] lg:w-[50%] xl:w-[35%] bg-[#F9F9F9]/[1] shadow-md p-[1.5rem] sm:p-[3rem] rounded-3xl">
            {/* heading */}
            <div className="flex flex-col gap-3 border-b">
              <div>
                <StyledH4Heading fontColor="text-black font-extrabold">
                  Welcome
                </StyledH4Heading>
                <p className="text-[14px] text-black ">
                  Let’s create your worker account.
                </p>
              </div>
              <div>
                <h2 className="text-[18px] text-black">
                  You were invited by{" "}
                  <span className="text-[#49977F] font-semibold">
                    {businessName}
                  </span>
                </h2>
              </div>
            </div>

            {/* input fields */}
            <div className="w-full flex flex-col gap-6">
              <div className="flex flex-col gap-5">
                <div>
                  <div className="bg-white rounded-[5px] w-full flex items-center px-4 gap-3 justify-between">
                    <Icon
                      icon="streamline:interface-user-circle-circle-geometric-human-person-single-user"
                      width="20"
                      height="20"
                      className="text-black"
                    />
                    <input
                      className={`text-lg w-full h-[55px] outline-0 ${
                        errors.name ? "border-red-500" : ""
                      }`}
                      placeholder="Full Name"
                      type="text"
                      name="name"
                      value={formValues.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  {errors.name && (
                    <p className="text-red-500 text-sm pl-4">{errors.name}</p>
                  )}
                </div>
                <div>
                  <div className="bg-white rounded-[5px] w-full flex items-center px-4 gap-3 justify-between">
                    <Icon
                      icon="mdi:lock-outline"
                      width="20"
                      height="20"
                      className="text-black"
                    />
                    <input
                      className={`text-lg w-full h-[55px] outline-0 ${
                        errors.password ? "border-red-500" : ""
                      }`}
                      placeholder="Enter Password"
                      type={passwordVisibility.password ? "text" : "password"}
                      value={formValues.password}
                      name="password"
                      onChange={handleInputChange}
                    />
                    <Icon
                      icon={
                        passwordVisibility.password
                          ? "ph:eye-bold"
                          : "iconamoon:eye-off-bold"
                      }
                      width="22"
                      height="22"
                      className="text-black cursor-pointer"
                      onClick={() =>
                        setPasswordVisibility((prevState) => ({
                          ...prevState,
                          password: !prevState.password,
                        }))
                      }
                    />
                  </div>
                  {errors.password && (
                    <p className="text-red-500 text-sm pl-4">
                      {errors.password}
                    </p>
                  )}
                </div>
                <div>
                  <div className="bg-white rounded-[5px] w-full flex items-center px-4 gap-3 justify-between">
                    <Icon
                      icon="streamline:lock-rotation"
                      width="20"
                      height="20"
                      className="text-black"
                    />
                    <input
                      className={`text-lg w-full h-[55px] outline-0 ${
                        errors.confirmPassword ? "border-red-500" : ""
                      }`}
                      placeholder="Repeat Password"
                      type={
                        passwordVisibility.repeatPassword ? "text" : "password"
                      }
                      value={formValues.confirmPassword}
                      onChange={handleInputChange}
                      name="confirmPassword"
                    />
                    <Icon
                      icon={
                        passwordVisibility.repeatPassword
                          ? "ph:eye-bold"
                          : "iconamoon:eye-off-bold"
                      }
                      width="22"
                      height="22"
                      className="text-black cursor-pointer"
                      onClick={() =>
                        setPasswordVisibility((prevState) => ({
                          ...prevState,
                          repeatPassword: !prevState.repeatPassword,
                        }))
                      }
                    />
                  </div>
                  {errors.confirmPassword && (
                    <p className="text-red-500 text-sm pl-4">
                      {errors.confirmPassword}
                    </p>
                  )}
                </div>
              </div>

              {/*register button */}
              <div>
                <button
                  onClick={handleSubmit}
                  className="bg-light-green w-full text-white lg:duration-300 lg:hover:bg-dark-green py-[15px] rounded-[100px]"
                >
                  <StyledMdParagraph
                    children={"Register Now"}
                    fontColor={"font-bold"}
                  />
                </button>
              </div>
            </div>
            <div>
              <StyledSmParagraph fontColor="text-[#979797]">
                <span className="font-semibold">Note:</span> This form can only
                be submitted once. If you navigate away, it will be understood
                that you no longer wish to register as a worker, and your
                invitation link will expire.
              </StyledSmParagraph>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BusinessRegistration;
