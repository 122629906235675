import React from 'react'
import Header from '../../components/layout/header/Header'

const SimpleLayout = ({ children }) => {
    return (
        <div>
            <Header simpleHeader />
            {children}
        </div>
    )
}

export default SimpleLayout
