import React, { Suspense } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import Homepage from "./pages/Homepage/Homepage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import BusinessRegistrationPage from "./pages/BusinessRegistrationPage/BusinessRegistrationPage";
import MainLayout from "./layouts/mainLayout/MainLayout";
import SimpleLayout from "./layouts/simpleLayout/SimpleLayout";
import RegistrationSuccessfull from "./sections/RegistrationSection/RegistrationSuccessfull";
import LinkExpiredPage from "./sections/RegistrationSection/TokenExpired";
import BankRegistrationPage from "./pages/BankRegistrationPage/BankRegistrationPage";
import BankRegistrationFailedPage from "./pages/BankRegistrationFailed/BankRegistrationFailedPage";
import DeleteAccount from "./pages/DeleteAccount/DeleteAccount";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsAndConditionPage from "./pages/TermsAndConditionPage/TermsAndConditionPage";

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <MainLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </MainLayout>
      ),
      children: [
        {
          path: "/",
          element: <Homepage />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicyPage />,
        },
        {
          path: "/terms-and-conditions",
          element: <TermsAndConditionPage />,
        },
        {
          path: "*",
          element: <Navigate to="/404" replace />,
        },
      ],
    },
    {
      element: (
        <SimpleLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </SimpleLayout>
      ),
      children: [
        {
          path: "/accept-invitation/:token",
          element: <BusinessRegistrationPage />,
        },
        {
          path: "/registration-successfull",
          element: <RegistrationSuccessfull />,
        },
        {
          path: "/link-expired",
          element: <LinkExpiredPage />,
        },
        {
          path: "/bank-registration-successfull",
          element: <BankRegistrationPage />,
        },
        {
          path: "/bank-registration-failed",
          element: <BankRegistrationFailedPage />,
        },
        {
          path: "/delete-account",
          element: <DeleteAccount />,
        },
      ],
    },
    {
      path: "/404",
      element: <NotFoundPage />,
    },
  ]);
  return routes;
}
