import React from 'react'
import StyledH5Heading from '../../common/components/styledH5Heading/StyledH5Heading'
import StyledLgParagraph from '../../common/components/styledLgParagraph/StyledLgParagraph'
import { StateContext } from '../../context/context'

const RegistrationSuccessfull = () => {
    const { businessName } = StateContext()
    return (
        <div>
            <div className='flex justify-center items-center w-full h-[90vh]'>
                <div className='flex flex-col justify-center items-center text-center gap-8 w-[90%] sm:w-[80%] md:w-[65%] lg:w-[50%] xl:w-[35%] bg-[#49977F] px-10 pt-[2rem] pb-[4rem] rounded-[22px]'>
                    <div>
                        <img src='/assets/verified.svg' alt='verified Icon' />
                    </div>
                    <div>
                        <StyledH5Heading fontColor='text-white font-extrabold mb-1'>
                            Registration Successful
                        </StyledH5Heading>
                        <StyledLgParagraph fontColor='text-white font-medium'>
                            {`Now you are a co-worker of ${businessName ? businessName : 'our company'}. Kindly proceed to the Lawn Olu app and login using the provided credentials.`}
                        </StyledLgParagraph>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrationSuccessfull
