import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../common/Button/Button";
import MobileNav from "./MobileNav";
import StyledLgParagraph from "../../../common/components/styledLgParagraph/StyledLgParagraph";

const Header = () => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const nav = [
    { link: "Home", href: "#home" },
    { link: "About Us", href: "#about-us" },
    { link: "Our Service", href: "#services" },
    { link: "Contact Us", href: "#contact-us" },
  ];
  useEffect(() => {
    if (pathname === "/" && state?.sectionId) {
      const section = document.querySelector(state.sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [pathname, state]);

  const scroll = (event, sectionId) => {
    event.preventDefault();
    if (pathname === "/") {
      const section = document.querySelector(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/", { state: { sectionId } });
    }
  };

  return (
    <div className="w-[100%] flex flex-col justify-center items-center bg-dark-green pb-4 lg:py-2">
      <div className=" lg:w-[90%] flex justify-between items-center py-2">
        <div className="hidden cursor-pointer lg:flex">
          <img className="" src="/assets/logo.png" alt="logo-img" />
        </div>
        <div className="hidden lg:flex">
          <ul className="flex items-center justify-between gap-6">
            {nav.map((items, index) => (
              <div key={index} className="">
                <NavLink
                  to={items?.href}
                  onClick={(e) => scroll(e, items?.href)}
                >
                  {""}
                  <li className="">
                    <StyledLgParagraph
                      children={items?.link}
                      fontColor={
                        "text-white hover:text-light-green active:text-orange font-semibold"
                      }
                    />
                  </li>
                </NavLink>
              </div>
            ))}
          </ul>
        </div>
        <div className="hidden lg:flex">
          <Button />
        </div>
      </div>
      <MobileNav />
    </div>
  );
};

export default Header;
