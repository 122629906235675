import React from "react";
import CustomStyledH2Heading from "../../../common/components/customStyledH2Heading/CustomStyledH2Heading";
import StyledLgParagraph from "../../../common/components/styledLgParagraph/StyledLgParagraph";
import ContactForm from "./ContactForm";

const ContactUsSection = () => {
  return (
    <section
      id="contact-us"
      className="w-[100%] flex flex-col justify-center items-center"
    >
      <div className="w-[90%] flex flex-col items-center lg:flex lg:flex-row justify-between lg:items-start gap-10 md:gap-12 lg:gap-16 xl:gap-36 py-8 lg:py-14">
        <div className=" w-[100%]  flex flex-col justify-center md:items-start gap-3 xl:gap-6">
          <div className="text-center lg:text-start ">
            <CustomStyledH2Heading
              children={"Contact Us "}
              fontColor={"text-dark-green font-semibold"}
              children2={"For A Free Consultation"}
              fontColor2={"text-light-green font-semibold"}
            />
          </div>
          <div className="text-center lg:text-start">
            <StyledLgParagraph
              children={
                "Looking for expert lawn care? Claim your no-obligation free consultation today! Fill out the form, and a lawn care professional will reach out to you soon."
              }
              fontColor={"text-dark-gray font-medium"}
            />
          </div>
        </div>
        {/* card */}
        <div className=" w-[100%] lg:w-[80%]">
          <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;
