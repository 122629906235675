import React from "react";
import StyledMdParagraph from "../../../../common/components/styledMdParagraph/StyledMdParagraph";

const DeleteForm = () => {
  return (
    <div className="flex flex-col items-start justify-center w-full gap-8 px-10 text-white bg-light-green py-14 rounded-2xl">
      <div className="w-full flex flex-col items-start xl:items-center justify-between  gap-8  xl:gap-10 xl:flex xl:flex-row">
        <input
          type="email"
          placeholder="Email"
          className="w-full input-green pb-2  outline-none placeholder:text-white "
        />
        <input
          type="password"
          placeholder="Password"
          className="w-full input-green pb-2  outline-none placeholder:text-white "
        />
      </div>
      <div className="w-full">
        <textarea
          type="text"
          placeholder="Provide Reason"
          className="w-full input-green  outline-none placeholder:text-white md:w-full pb-14 overflow-x-hidden overflow-y-auto no-scrollbar"
          style={{
            resize: "none",
            scrollbarWidth: "none",
          }}
        />
      </div>

      <div className="w-full">
        <button className="w-full px-10 py-4 duration-500 bg-white rounded-full md:py-4 md:px-20 text-light-green hover:bg-dark-green hover:text-white">
          <StyledMdParagraph
            children={"Request"}
            fontColor={" font-medium !font-bricolage"}
          />
        </button>
      </div>
    </div>
  );
};

export default DeleteForm;
