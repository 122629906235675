import React from "react";
import StyledH2Heading from "../../../common/components/styledH2Heading/StyledH2Heading";
import StyledLgParagraph from "../../../common/components/styledLgParagraph/StyledLgParagraph";
import Card from "./Card";

const TestimonialSection = () => {
  const ary = [
    {
      userImage: "/assets/user1.png",
      title: "Michael R.",
      subTitle: "- Homeowner",
      starImage: [
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
      ],
      desc: "Lawnolu made finding a professional landscaper so easy! I booked a lawn care service inminutes, and the team showed up on time and did an amazing job. My yard has never looked Better!",
    },
    {
      userImage: "/assets/user3.png",
      title: "David L.",
      subTitle: "- HOA Manager",
      starImage: [
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
      ],
      desc: "Managing multiple properties can be a hassle, but Lawnolu has simplified our landscaping needs. Their vetted professionals are reliable, affordable, and always deliver quality service.",
    },
    {
      userImage: "/assets/jessica.png",
      title: "Jessica M.",
      subTitle: "- Plant Parent",
      starImage: [
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
      ],
      desc: "I needed help with my greenhouse and was impressed by the experienced horticulturist I found through Lawnolu. My plants are thriving thanks to their expert care!",
    },
    {
      userImage: "/assets/emily.png",
      title: "Emily S.",
      subTitle: "- Homeowner",
      starImage: [
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
      ],
      desc: "I had an issue with my sprinkler system, and Lawnolu helped me find a technician on short notice. The repair was quick, affordable, and stress-free. Fantastic service!”",
    },
    {
      userImage: "/assets/sarah.png",
      title: "Sarah T.",
      subTitle: "- Small Business Owner",
      starImage: [
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
      ],
      desc: "Our storefront needed a landscaping refresh, and Lawnolu connected us with a great team. They installed a beautiful flower bed and handled everything professionally. Highly recommend!",
    },
    {
      userImage: "/assets/user5.png",
      title: "Robert C.",
      subTitle: "- Property Manager",
      starImage: [
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
        { image: "/assets/star.png" },
      ],
      desc: "Scheduling landscaping services used to be a headache, but Lawnolu makes it seamless. I can easily book seasonal maintenance for multiple properties with just a few clicks!",
    },
  ];
  return (
    <div className="w-[100%] flex flex-col justify-center items-center">
      <div className="w-[90%] flex flex-col justify-center items-center gap-10 md:gap-14 py-8 lg:py-14">
        <div className=" md:w-[70%] lg:w-[60%] flex flex-col justify-center items-center gap-4 ">
          <div className="text-center leading-none">
            <StyledH2Heading
              children={"Why People Love Lawnolu"}
              fontColor={"text-light-green font-semibold"}
            />
          </div>
          <div className="text-center">
            <StyledLgParagraph
              children={
                "Don’t just take our word for it, read what our customers say about Lawnolu."
              }
              fontColor={"text-dark-gray font-medium"}
            />
          </div>
        </div>
        {/* card section */}
        <div className="flex flex-col justify-center items-center gap-4 md:gap-6 md:grid grid-cols-2 lg:grid lg:grid-cols-3 ">
          {ary.map((items, index) => (
            <div
              key={index}
              className="  py-4 px-4 xl:py-8 xl:px-6 border-2 md:border-none rounded-2xl shadow-md transition ease-in-out  hover:-translate-y-1 hover:scale-105 duration-300 "
            >
              <Card item={items} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
