import React from "react";

const CustomStyledH1Heading = ({
  children,
  children2,
  children3,
  fontColor,
  fontColor2,
}) => {
  return (
    <h1 className={`text-h1 font-bold font-bricolage ${fontColor}`}>
      {children}
      <span className={`${fontColor2}`}>{children2}</span>
      {children3}
    </h1>
  );
};
export default CustomStyledH1Heading;
