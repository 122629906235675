import React, { createContext, useState, useContext } from "react";

const Context = createContext();

export const StateContext = () => useContext(Context);

export const ContextProvider = ({ children }) => {
  const [businessName, setBusinessName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <Context.Provider
      value={{
        businessName,
        errorMessage,
        setBusinessName,
        setErrorMessage,
      }}
    >
      {children}
    </Context.Provider>
  );
};
