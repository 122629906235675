import { Link } from 'react-router-dom';
import Logo from '../../components/404/Logo';

// import Logo404 from 'src/components/logo/logo404';

export default function NotFoundPage() {
  const renderHeader = (
    <header className="lineHeight-0">
      <Logo type="404" />
    </header>
  );

  return (
    <>
      {renderHeader}
      <div className="mt-2 mx-auto max-w-[350px] flex text-center items-center flex-col justify-center">
        <img
          src="/assets/illustration_404.svg"
          alt="404 Illustration"
          className="mx-auto h-[260px]"
        />
      </div>
      <div className="py-3 mx-auto max-w-[480px] flex text-center items-center flex-col justify-center">
        <h3 className="mb-2 text-3xl">Sorry, page not found!</h3>
        <p className="mb-2 text-gray-500">
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
          sure to check your spelling.
        </p>
        <Link to="/">
          <button className="px-8 py-3 text-lg font-medium text-white bg-[#1E3230] rounded-lg hover:bg-[#172824]">
            Go to Home
          </button>
        </Link>
      </div>
    </>
  );
}
