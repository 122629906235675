import React from "react";
import CustomStyledH2Heading from "../../../common/components/customStyledH2Heading/CustomStyledH2Heading";
import Card from "./Card";

const ServiceSection = () => {
  const ary = [
    {
      image: "/assets/icon1.png",
      title: "Lawn Care",
      desc: "Read More...",
      para: "Because the weekends shouldn’t be spent behind a lawnmower. Our trusted pros handle mowing, trimming, fertilizing, and more, while you enjoy a perfectly manicured lawn. Whether you're a homeowner, business, or HOA, we make lawn care effortless.",
    },
    {
      image: "/assets/icon2.png",
      title: "Seasonal Maintenance",
      desc: "Read More...",
      para: "Seasons change, but a well-maintained lawn never goes out of style. From storm prep to summer weed control and fall leaf removal, our lawn care professionals trim, clear, and keeps lawns thriving so it’s safe, tidy, and beautiful no matter the forecast.",
    },
    {
      image: "/assets/icon3.png",
      title: "Free Consultations",
      desc: "Read More...",
      para: "Not sure what your lawn needs? Let’s take a look! Our experts will assess your grass type, soil health, and any problem areas, then recommend the best solutions to keep your yard in peak condition. No pressure, no commitments—just expert advice, free of charge.",
    },
    {
      image: "/assets/icon4.png",
      title: "Irrigation Systems",
      desc: "Read More...",
      para: "Keep your lawn perfectly watered without wasting time or resources. From sprinkler installations and drip irrigation to routine maintenance and repairs, our professionals help you keep your landscape and community fresh, green, and healthy, rain or shine.",
    },
    {
      image: "/assets/icon5.png",
      title: "Water Features",
      desc: "Read More...",
      para: "Transform your outdoor space with a stunning water feature designed just for you. Whether it's a peaceful pond, a cascading waterfall, or a sleek fountain, our expert installers bring your vision to life, adding elegance and tranquility to any yard or commercial property.",
    },
    {
      image: "/assets/icon6.png",
      title: "Professional Gardeners",
      desc: "Read More...",
      para: "Bring your garden to life with expert plant care tailored to your space. Whether it’s seasonal flower beds, pruning, long-term landscaping, or soil conditioning, skilled gardeners plant, nourish, and maintain your landscape so it thrives year-round.",
    },
  ];
  return (
    <section
      id="services"
      className="w-[100%] flex flex-col justify-center items-center"
    >
      <div className="w-[90%] flex flex-col justify-between items-center gap-8  lg:gap-10 py-10 lg:py-0 xl:py-14">
        <div className="md:w-[70%] lg:w-[55%] flex flex-col justify-center items-center gap-3">
          <div className="">
            <CustomStyledH2Heading
              children={"Our"}
              fontColor={"text-dark-green font-semibold "}
              children2={" Service "}
              fontColor2={"text-light-green font-semibold"}
            />
          </div>
          {/* <div>
            <StyledLgParagraph
              children={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus hendrerit suscipit egestas. Nunc eget congue ante. Vivamus ut sapien et ex volutpat tincidunt eget at felis vivamus hendrerit."
              }
              fontColor={"text-dark-gray text-center"}
            />
          </div> */}
        </div>
        {/* card section */}
        <div className="w-[100%] flex flex-col justify-between items-center md:grid md:grid-cols-2 gap-4  ">
          {ary.map((items, index) => (
            <div className=" shadow-md rounded-2xl transition ease-in-out  hover:-translate-y-1 hover:scale-[1.02] duration-300">
              <Card key={index} item={items} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
