import React from "react";
import StyledH5Heading from "../../../../common/components/styledH5Heading/StyledH5Heading";
import StyledLgParagraph from "../../../../common/components/styledLgParagraph/StyledLgParagraph";

const Card = ({ image, content }) => {
  return (
    <div className="w-full h-[300px] md:h-[350px] lg:h-[400px] flex flex-col justify-center items-center">
      {image && (
        <div className="w-full h-full flex flex-col justify-center items-center ">
          <img className="w-full h-full object-cover rounded-2xl" src={image} alt="Image" />
        </div>
      )}
      {content && (
        <div className="w-full h-full px-4 py-6 flex flex-col justify-center items-center bg-light-green gap-3 rounded-2xl">
          <div className="text-center">
            <StyledH5Heading children={content.title} fontColor={"text-white font-semibold"} />
          </div>
          <div className="text-center">
            <StyledLgParagraph children={content.desc} fontColor={"text-white font-medium"} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
