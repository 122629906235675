import React, { useEffect, useState } from "react";
import StyledMdParagraph from "../../../../common/components/styledMdParagraph/StyledMdParagraph";

const Button = () => {
  const [appStoreLink, setAppStoreLink] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      // iOS device
      setAppStoreLink("https://www.apple.com/app-store/");
    } else {
      // Android or other devices (default to Play Store)
      setAppStoreLink("https://play.google.com/store/apps");
    }
  }, []);
  return (
    <div>
      <a href={appStoreLink} target="_blank" rel="noreferrer">
        <button className="bg-light-green text-white  lg:duration-700 lg:hover:bg-white lg:hover:text-dark-green  px-10 py-2.5 rounded-[100px]">
          <StyledMdParagraph
            children={"Get the App"}
            fontColor={"font-semibold"}
          />
        </button>
      </a>
    </div>
  );
};

export default Button;
