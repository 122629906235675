import React, { useEffect } from "react";
import CustomStyledH2Heading from "../../common/components/customStyledH2Heading/CustomStyledH2Heading";
import { useLocation } from "react-router-dom";
import StyledH5Heading from "../../common/components/styledH5Heading/StyledH5Heading";
import StyledMdParagraph from "../../common/components/styledMdParagraph/StyledMdParagraph";

const PrivacyPolicy = () => {
  const location = useLocation();

  useEffect(() => {
    const scrollToHash = () => {
      if (location.hash) {
        const sectionId = location.hash.substring(1);
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        } else {
          console.warn(`Section with ID '${sectionId}' not found.`);
        }
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    };
    setTimeout(scrollToHash, 100);
  }, [location]);
  return (
    <>
      <section id="privacy-section" className="flex min-h-screen ">
        <div className="flex flex-col w-full max-w-4xl gap-4 p-8 mx-auto md:gap-4 md:py-20 md:px-10">
          <div className="w-full text-center lg:pb-10 md:pb-8 xs:pb-0">
            <CustomStyledH2Heading className="font-semibold text-center ">
              Privacy Policy
            </CustomStyledH2Heading>
          </div>
          <div className="flex flex-col w-full font-mulish text-dark-gray ">
            <div className="font-semibold text-black">
              <StyledH5Heading>Article 1 - DEFINITIONS:</StyledH5Heading>
            </div>
            <div>
              <StyledMdParagraph >
                <ol class="space-y-4 list-decimal list-inside custom-list">
                  <ol class="  mt-2 space-y-1 list-none">
                    <li className="">
                      <span className="font-extrabold text-black">
                        {" "}
                        MOBILE APPLICATION:{" "}
                      </span>{" "}
                      This Privacy Policy will refer to and be applicable to the
                      Mobile App listed above, which shall hereinafter be
                      referred to as "Mobile App."
                    </li>
                    <li className="py-3">
                      <span className="font-extrabold text-black">
                        {" "}
                        EFFECTIVE DATE:{" "}
                      </span>{" "}
                      "Effective Date" means the date this Privacy Policy comes
                      into force and effect.
                    </li>
                    <li className="py-3">
                      <span className="font-extrabold text-black">
                        {" "}
                        PARTIES:{" "}
                      </span>{" "}
                      The parties to this privacy policy are the following data
                      controller: Lawnolu ("Data Controller") and you, as the
                      user of this Mobile App. Hereinafter, the parties will
                      individually be referred to as "Party" and collectively as
                      "Parties."
                    </li>
                    <li className="py-3">
                      <span className="font-extrabold text-black">
                        {" "}
                        DATA CONTROLLER:{" "}
                      </span>{" "}
                      Data Controller is the publisher, owner, and operator of
                      the Mobile App and is the Party responsible for the
                      collection of information described herein. Data
                      Controller shall be referred to either by Data
                      Controller's name or "Data Controller," as listed above.
                      If Data Controller or Data Controller's property shall be
                      referred to through first-person pronouns, it shall be
                      through the use of the following: us, we, our, ours, etc.
                    </li>
                    <li className="py-3">
                      <span className="font-extrabold text-black"> YOU: </span>{" "}
                      Should you agree to this Privacy Policy and continue your
                      use of the Mobile App, you will be referred to herein as
                      either you, the user, or if any second-person pronouns are
                      required and applicable, such pronouns as 'your", "yours",
                      etc.
                    </li>
                    <li className="py-3">
                      <span className="font-extrabold text-black">
                        {" "}
                        SERVICES:{" "}
                      </span>{" "}
                      Data Controller is the publisher, owner, and operator of
                      the Mobile App and is the Party responsible for the
                      collection of information described herein. Data
                      Controller shall be referred to either by Data
                      Controller's name or "Data Controller," as listed above.
                      If Data Controller or Data Controller's property shall be
                      referred to through first-person pronouns, it shall be
                      through the use of the following: us, we, our, ours, etc.
                    </li>

                    <li className="py-3">
                      <span className="font-extrabold text-black">
                        {" "}
                        PERSONAL DATA:{" "}
                      </span>{" "}
                      "Personal DATA" means personal data and information that
                      we obtain from you in connection with your use of the
                      Mobile App that is capable of identifying you in any
                      manner.
                    </li>
                  </ol>
                </ol>
              </StyledMdParagraph>
            </div>
          </div>
          <div className="flex flex-col w-full font-mulish text-dark-gray ">
            <div className="pb-2 font-semibold text-black">
              <StyledH5Heading>
                Article 2 - GENERAL INFORMATION:
              </StyledH5Heading>
            </div>
            <div className="flex flex-col gap-5">
              <div>
                <StyledMdParagraph >
                  This privacy policy (hereinafter "Privacy Policy") describes
                  how we collect and use the Personal Data that we receive about
                  you, as well as your rights in relation to that Personal Data,
                  when you visit our Mobile App or use our Services.
                </StyledMdParagraph>
              </div>

              <div>
                <StyledMdParagraph >
                  This Privacy Policy does not cover any information that we may
                  receive about you through sources other than the use of our
                  Mobile App. The Mobile App may link out to other websites or
                  mobile applications, but this Privacy Policy does not and will
                  not apply to any of those linked websites or applications.
                </StyledMdParagraph>
              </div>
              <div>
                <StyledMdParagraph >
                  We are committed to the protection of your privacy while you
                  use our Mobile App.
                </StyledMdParagraph>
              </div>
              <div>
                <StyledMdParagraph >
                  By continuing to use our Mobile App, you acknowledge that you
                  have had the chance to review and consider this Privacy
                  Policy, and you acknowledge that you agree to it. This means
                  that you also consent to the use of your information and the
                  method of disclosure as described in this Privacy Policy. If
                  you do not understand the Privacy Policy or do not agree to
                  it, then you agree to immediately cease your use of our Mobile
                  App.
                </StyledMdParagraph>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full font-mulish text-dark-gray ">
            <div className="pb-2 font-semibold text-black">
              <StyledH5Heading>Article 3 - CONTACT:</StyledH5Heading>
            </div>
            <div className="flex flex-col gap-5">
              <div>
                <StyledMdParagraph >
                  The Party responsible for the processing of your personal data
                  is as follows: Lawnolu. <br />
                  The Data Controller may be contacted as follows:
                </StyledMdParagraph>
              </div>

              <div>
                <StyledMdParagraph >
                  info@lawnolu.com <br />
                  10640 Pebble Cove Lane, Boca Raton, Fl 33498
                </StyledMdParagraph>
              </div>
              <div>
                <StyledMdParagraph >
                  The Data Controller and operator of the Mobile App are one and
                  the same.
                </StyledMdParagraph>
              </div>
              <div>
                <StyledMdParagraph >
                  By continuing to use our Mobile App, you acknowledge that you
                  have had the chance to review and consider this Privacy
                  Policy, and you acknowledge that you agree to it. This means
                  that you also consent to the use of your information and the
                  method of disclosure as described in this Privacy Policy. If
                  you do not understand the Privacy Policy or do not agree to
                  it, then you agree to immediately cease your use of our Mobile
                  App.
                </StyledMdParagraph>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full font-mulish text-dark-gray ">
            <div className="pb-2 font-semibold text-black">
              <StyledH5Heading>Article 4 - LOCATION:</StyledH5Heading>
            </div>
            <div className="flex flex-col gap-5">
              <div>
                <StyledMdParagraph >
                  Please be advised the data processing activities take place in
                  the United States, outside the European Economic Area. Data
                  may also be transferred to companies within the United States,
                  but will only be done so in a manner that complies with the
                  EU's General Data Protection Regulation or GDPR. The location
                  where the data processing activities take place is as follows:
                </StyledMdParagraph>
              </div>
              <div>
                <StyledMdParagraph >
                  Boca Raton, Florida 33498
                </StyledMdParagraph>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full font-mulish ">
            <div className="pb-2 font-semibold">
              <StyledH5Heading>
                Article 5 - MODIFICATIONS AND REVISIONS:
              </StyledH5Heading>
            </div>
            <div className="flex flex-col gap-5">
              <div>
                <StyledMdParagraph >
                  We reserve the right to modify, revise, or otherwise amend
                  this Privacy Policy at any time and in any manner. If we do
                  so, however, we will notify you and obtain your consent to the
                  change in processing. Unless we specifically obtain your
                  consent, any changes to the Privacy Policy will only impact
                  the information collected on or after the date of the change.
                  It is also your responsibility to periodically check this page
                  for any such modification, revision or amendment. Boca Raton,
                  Florida 33498
                </StyledMdParagraph>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full font-mulish ">
            <div className="pb-2 font-semibold">
              <StyledH5Heading>
                Article 6 - THE PERSONAL DATA WE RECEIVE FROM YOU:
              </StyledH5Heading>
            </div>
            <div className="flex flex-col gap-5 text-dark-gray">
              <StyledMdParagraph fontColor="text-dark-gray">
                Depending on how you use our Mobile App, you will be subject to
                different types of Personal Data collected and different manners
                of collection:
                <div>
                  <StyledMdParagraph fontColor="text-dark-gray">
                    <ol class="space-y-4 text-dark-gray list-decimal list-inside custom-list">
                      <ol class=" md:pl-10  mt-2 space-y-1 list-none">
                        <li className="">
                          <span className="font-extrabold text-black">
                            {" "}
                            Registered users:{" "}
                          </span>{" "}
                          You, as a user of the Mobile App, may be asked to
                          register in order to use the Mobile App or to purchase
                          the Services available for sale.
                          <div class="py-2">
                            <div>
                              During the process of your registration, we will
                              collect some of the following Personal Data from
                              you through your voluntary disclosure:
                            </div>
                            <div class="pl-0 py-2 md:pl-10 ">
                              Name, Email Address, Physical Address, Telephone
                              number, Facebook profile address
                            </div>
                          </div>
                          <div class="space-y-5">
                            <div>
                              Personal Data may be asked for in relation to:
                            </div>
                            <div class="pl-0 space-y-5 md:pl-10">
                              <div>
                                <b>I)</b> Interaction with our representatives
                                in any way
                              </div>
                              <div>
                                <b>II)</b> Making purchases
                              </div>
                              <div>
                                <b>III)</b> Receiving notifications by text
                                message or email about marketing
                              </div>
                              <div>
                                <b>IV)</b> Receiving general emails from us
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col gap-5 py-5">
                            <div>
                              By undergoing the registration process, you
                              consent to us collecting your Personal Data,
                              including the Personal Data described in this
                              clause, as well as storing, using or disclosing
                              your Personal Data in accordance with this Privacy
                              Policy
                            </div>
                            <div>
                              <span className="font-extrabold text-black">
                                b) Unregistered users:
                              </span>{" "}
                              If you are a passive user of the Mobile App and do
                              not register for any purchases or other service,
                              you may still be subject to certain passive data
                              collection ("Passive Data Collection"). Such
                              Passive Data Collection may include through
                              cookies, as described below, IP address
                              information, location information, and certain
                              browser data, such as history and/or session
                              information.
                            </div>
                            <div>
                              <span className="font-extrabold text-black">
                                c) All users:
                              </span>{" "}
                              If you are a passive user of the Mobile App and do
                              not register for any purchases or other service,
                              you may still be subject to certain passive data
                              collection ("Passive Data Collection"). Such
                              Passive Data Collection may include through
                              cookies, as described below, IP address
                              information, location information, and certain
                              browser data, such as history and/or session
                              information.
                            </div>
                            <div>
                              <span className="font-extrabold text-black">
                                d) Sales & Billing Information:
                              </span>{" "}
                              In order to purchase any of the services on the
                              Mobile App, you will be asked to provide certain
                              credit information, billing address information,
                              and possibly additional specific information so
                              that you may be properly charged for your
                              purchases. This payment and billing information
                              may be stored for the following period: 1 year. If
                              so, it will be used exclusively to assist you with
                              making future purchases with us.
                            </div>
                            <div className="flex flex-col gap-2">
                              <div>
                                <span className="font-extrabold text-black">
                                  e) Related Entities:
                                </span>{" "}
                                We may share your Personal Data, including
                                Personal Data that identifies you personally,
                                with any of our parent companies, subsidiary
                                companies, affiliates or other trusted related
                                entities.
                              </div>

                              <div>
                                However, we only share your Personal Data with a
                                trusted related entity if that entity agrees to
                                our privacy standards as set out in this Privacy
                                Policy and to treat your Personal Data in the
                                same manner that we do.
                              </div>
                              <div>
                                <span className="font-extrabold text-black">
                                  f) Email Marketing:
                                </span>{" "}
                                You may be asked to provide certain Personal
                                Data, such as your name and email address, for
                                the purpose of receiving email marketing
                                communications. This information will only be
                                obtained through your voluntary disclosure and
                                you will be asked to affirmatively opt-in to
                                email marketing communications.
                              </div>
                              <div>
                                <span className="font-extrabold text-black">
                                  g) User Experience:
                                </span>{" "}
                                From time to time we may request information
                                from you to assist us in improving our Mobile
                                App, and the Services we sell, such as
                                demographic information or your particular
                                preferences.
                              </div>
                              <div>
                                <span className="font-extrabold text-black">
                                  h) Content Interaction:
                                </span>{" "}
                                Our Mobile App may allow you to comment on the
                                content that we provide or the content that
                                other users provide, such as blogs, multimedia,
                                or forum posts. If so, we may collect some
                                Personal Data from you at that time, such as,
                                but not limited to, username or email address.
                              </div>
                              <div>
                                <span className="font-extrabold text-black">
                                  i) Combined or Aggregated Information:
                                </span>{" "}
                                We may combine or aggregate some of your
                                Personal Data in order to better serve you and
                                to better enhance and update our Mobile App for
                                your and other consumers' use.
                              </div>

                              <div>
                                We may also share such aggregated information
                                with others, but only if that aggregated
                                information does not contain any Personal Data.
                              </div>
                            </div>
                          </div>
                        </li>
                      </ol>
                    </ol>
                  </StyledMdParagraph>
                </div>
              </StyledMdParagraph>
            </div>
            <div className="flex flex-col w-full font-mulish ">
              <div className="pb-2 font-semibold">
                <StyledH5Heading>
                  Article 7 - THE PERSONAL DATA WE RECEIVE AUTOMATICALLY:
                </StyledH5Heading>
              </div>
              <p class="text-dark-gray text-md font-normal">
                <div class="space-y-5">
                  <div>
                    <b className="text-black">Cookies:</b> We may collect
                    information from you through automatic tracking systems
                    (such as information about your browsing preferences) as
                    well as through information that you volunteer to us (such
                    as information that you provide during a registration
                    process or at other times while using the Mobile App, as
                    described above).
                  </div>
                  <div>
                    <b className="text-black">For example</b>, we use cookies to
                    make your browsing experience easier and more intuitive:
                    cookies are small strings of text used to store some
                    information that may concern the user, his or her
                    preferences or the device they are using to access the
                    internet (such as a computer, tablet, or mobile phone).
                    Cookies are mainly used to adapt the operation of the site
                    to your expectations, offering a more personalized browsing
                    experience and memorizing the choices you made previously.
                  </div>
                  <div>
                    A cookie consists of a reduced set of data transferred to
                    your browser from a web server and it can only be read by
                    the server that made the transfer. This is not executable
                    code and does not transmit viruses.
                  </div>
                  <div class="space-y-5">
                    <div>
                      Cookies do not record or store any Personal Data. If you
                      want, you can prevent the use of cookies, but then you may
                      not be able to use our Mobile App as we intend. To proceed
                      without changing the options related to cookies, simply
                      continue to use our Mobile App.
                    </div>
                    <div class="pl-0 space-y-5 md:pl-10">
                      <div>
                        <b className="text-black">Technical cookies: </b>
                        Technical cookies, which can also sometimes be called
                        HTML cookies, are used for navigation and to facilitate
                        your access to and use of the site. They are necessary
                        for the transmission of communications on the network or
                        to supply services requested by you. The use of
                        technical cookies allows the safe and efficient use of
                        the site.
                      </div>
                      <div>
                        You can manage or request the general deactivation or
                        cancelation of cookies through your browser. If you do
                        this though, please be advised this action might slow
                        down or prevent access to some parts of the site.
                      </div>
                      <div>
                        Cookies may also be retransmitted by an analytics or
                        statistics provider to collect aggregated information on
                        the number of users and how they visit the Mobile App.
                        These are also considered technical cookies when they
                        operate as described.
                      </div>
                      <div>
                        Temporary session cookies are deleted automatically at
                        the end of the browsing session - these are mostly used
                        to identify you and ensure that you don't have to log in
                        each time - whereas permanent cookies remain active
                        longer than just one particular session.
                      </div>
                      <div>
                        <b className="text-black">Third-party cookies: </b>We
                        may also utilize third-party cookies, which are cookies
                        sent by a third-party to your computer. Permanent
                        cookies are often third-party cookies. The majority of
                        third-party cookies consist of tracking cookies used to
                        identify online behavior, understand interests and then
                        customize advertising for users.
                      </div>
                      <div>
                        Third-party analytical cookies may also be installed.
                        They are sent from the domains of the aforementioned
                        third parties external to the site. Third-party
                        analytical cookies are used to detect information on
                        user behavior on our Mobile App. This place anonymously,
                        in order to monitor the performance and improve the
                        usability of the site. Third-party profiling cookies are
                        used to create profiles relating to users, in order to
                        propose advertising in line with the choices expressed
                        by the users themselves.
                      </div>
                      <div>
                        <b className="text-black">Profiling cookies: </b> We may
                        also use profiling cookies, which are those that create
                        profiles related to the user and are used in order to
                        send advertising to the user's browser.
                      </div>
                      <div>
                        When these types of cookies are used, we will receive
                        your explicit consent.
                      </div>
                      <div>
                        <b className="text-black">
                          Support in configuring your browser:{" "}
                        </b>
                        You can manage cookies through the settings of your
                        browser on your device. However, deleting cookies from
                        your browser may remove the preferences you have set for
                        this Mobile App.
                      </div>
                      <div class="space-y-5">
                        <div>
                          For further information and support, you can also
                          visit the specific help page of the web browser you
                          are using:
                        </div>
                        <div class="space-y-5 md:pl-5">
                          <div class="flex flex-col">
                            <b className="text-black">- Internet Explorer:</b>{" "}
                            <a
                              target="__blank"
                              class="text-[#0035b1d5] hover:text-[#6466dd] underline md:pl-5"
                              href="http://windows.microsoft.com/en-us/windows-vista/block-or%20allow-cookies"
                            >
                              http://windows.microsoft.com/en-us/windows-vista/block-or%20allow-cookies
                            </a>
                          </div>
                          <div class="flex flex-col">
                            <b className="text-black">- Firefox:</b>{" "}
                            <a
                              target="__blank"
                              class="text-[#0035b1d5] hover:text-[#6466dd] underline md:pl-5"
                              href="https://support.mozilla.org/en-us/kb/enable-and-disable-cookies-website-preferences"
                            >
                              https://support.mozilla.org/en-us/kb/enable-and-disable-cookies-website-preferences
                            </a>
                          </div>
                          <div class="flex flex-col">
                            <b className="text-black">- Safari:</b>{" "}
                            <a
                              target="__blank"
                              class="text-[#0035b1d5] hover:text-[#6466dd] underline md:pl-5"
                              href="http://www.apple.com/legal/privacy/"
                            >
                              http://www.apple.com/legal/privacy/
                            </a>
                          </div>
                          <div class="flex flex-col">
                            <b className="text-black">- Chrome:</b>{" "}
                            <a
                              target="__blank"
                              class="text-[#0035b1d5] hover:text-[#6466dd] underline md:pl-5"
                              href="https://support.google.com/accounts/answer/61416?hl=en"
                            >
                              https://support.google.com/accounts/answer/61416?hl=en
                            </a>
                          </div>
                          <div class="flex flex-col">
                            <div>
                              <b className="text-black">- Opera:</b>
                            </div>{" "}
                            <div>
                              <a
                                target="__blank"
                                class="text-[#0035b1d5] hover:text-[#6466dd] underline md:pl-5"
                                href="http://www.opera.com/help/tutorials/security/cookies/"
                              >
                                http://www.opera.com/help/tutorials/security/cookies/
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="space-y-5">
                        <div>
                          <b className="text-black">Log Data: </b>Like all
                          websites and mobile applications, this Mobile App also
                          makes use of log files that store automatic
                          information collected during user visits. The
                          different types of log data could be as follows:
                        </div>
                        <div class="pl-0 md:pl-10">
                          <div>- Internet protocol (IP) address;</div>
                          <div>
                            - Type of browser and device parameters used to
                            connect to the Mobile App;
                          </div>
                          <div>
                            - Name of the Internet Service Provider (ISP);
                          </div>
                          <div>- date and time of visit;</div>
                          <div>
                            - Web page of origin of the user (referral) and
                            exit;
                          </div>
                          <div>- Possibly the number of clicks.</div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div>
                    The aforementioned information is processed in an automated
                    form and collected in an exclusively aggregated manner in
                    order to verify the correct functioning of the site, and for
                    security reasons. This information will be processed
                    according to the legitimate interests of the Data
                    Controller.
                  </div>
                  <div>
                    For security purposes (spam filters, firewalls, virus
                    detection), the automatically recorded data may also
                    possibly include Personal Data such as IP address, which
                    could be used, in accordance with applicable laws, in order
                    to block attempts at damage to the Mobile App or damage to
                    other users, or in the case of harmful activities or crime.
                    Such data are never used for the identification or profiling
                    of the user, but only for the protection of the Mobile App
                    and our users. Such information will be treated according to
                    the legitimate interests of the Data Controller.
                  </div>
                </div>
              </p>
            </div>
          </div>
          <div className="flex flex-col w-full font-mulish ">
            <div className="pb-2 font-semibold">
              <StyledH5Heading>Article 8 - THIRD PARTIES:</StyledH5Heading>
            </div>
            <div class="space-y-5 text-dark-gray">
              <div>
                We may utilize third-party service providers ("Third-Party
                Service Providers"), from time to time or all the time, to help
                us with our Mobile App, and to help serve you.
              </div>
              <div class="pl-0 space-y-5 md:pl-10">
                <div>
                  We may use Third-Party Service Providers to assist with
                  information storage (such as cloud storage).
                </div>
                <div>
                  We may provide some of your Personal Data to Third-Party
                  Service Providers in order to help us track usage data, such
                  as referral websites, dates and times of page requests, etc.
                  We use this information to understand patterns of usage of,
                  and to improve, the Mobile App.
                </div>
                <div>
                  We may use Third-Party Service Providers to host the Mobile
                  App. In this instance, the Third-Party Service Provider will
                  have access to your Personal Data.
                </div>
                <div>
                  We may use Third-Party Service Providers to fulfill orders in
                  relation to the Mobile App.
                </div>
                <div>
                  We may allow third parties to advertise on the Mobile App.
                  These third parties may use cookies in connection with their
                  advertisements (see the "Cookies" clause in this Privacy
                  Policy).
                </div>
                <div>
                  We may use Third-Party Service Providers for the following
                  services in relation to the Mobile App:
                  <div className="py-2 ps-5">
                    The website uses third-party comment moderators to review
                    and look over all comments and then approve or deny
                  </div>
                </div>
                <div>
                  <div>
                    We only share your Personal Data with a Third-Party Service
                    Provider if that provider agrees to our privacy standards as
                    set out in this Privacy Policy.
                  </div>
                </div>
                <div>
                  Notwithstanding the other provisions of this Privacy Policy,
                  we may provide your Personal Data to a third party or to third
                  parties in order to protect the rights, property or safety, of
                  us, our customers or third parties, or as otherwise required
                  by law.
                </div>
                <div>
                  We will not knowingly share your Personal Data with any third
                  parties other than in accordance with this Privacy Policy.
                </div>
                <div>
                  If your Personal Data might be provided to a third party in a
                  manner that is other than as explained in this Privacy Policy,
                  you will be notified. You will also have the opportunity to
                  request that we not share that information.
                </div>
                <div>
                  In general, you may request that we do not share your Personal
                  Data with third parties. Please contact us via email, if so.
                  Please be advised that you may lose access to certain services
                  that we rely on third-party providers for.
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full font-mulish ">
            <div className="pb-2 font-semibold">
              <StyledH5Heading>
                Article 9 - HOW PERSONAL DATA IS STORED:
              </StyledH5Heading>
            </div>
            <div class="space-y-5 text-dark-gray">
              <div>
                We use secure physical and digital systems to store your
                Personal Data when appropriate. We ensure that your Personal
                Data is protected against unauthorized access, disclosure, or
                destruction.
              </div>
              <div>
                Please note, however, that no system involving the transmission
                of information via the internet, or the electronic storage of
                data, is completely secure. However, we take the protection and
                storage of your Personal Data very seriously. We take all
                reasonable steps to protect your Personal Data.
              </div>
              <div class="space-y-5">
                <div>
                  The systems that we use to store your information include but
                  are not limited to:
                </div>
                <div class="pl-0 font-bold text-black md:pl-10">
                  We ensure that only a handful of staff members (not more than
                  5) have access to personal user information data, and we use
                  an outside monitoring service to make sure we never have data
                  breaches.
                </div>
              </div>
              <div>
                Personal Data is stored throughout your relationship with us. We
                delete your Personal Data upon request for cancelation of your
                account or other general request for the deletion of data.
              </div>
              <div>
                In the event of a breach of your Personal Data, you will be
                notified in a reasonable time frame, but in no event later than
                two weeks, and we will follow all applicable laws regarding such
                breach.
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full font-mulish ">
            <div className="pb-2 font-semibold">
              <StyledH5Heading>
                Article 10 - PURPOSES OF PROCESSING OF PERSONAL DATA:
              </StyledH5Heading>
            </div>
            <div class="space-y-5 text-dark-gray">
              <div>
                We primarily use your Personal Data to help us provide a better
                experience for you on our Mobile App and to provide you the
                services and/or information you may have requested, such as use
                of our Mobile App.
              </div>
              <div>
                Information that does not identify you personally, but that may
                assist in providing us broad overviews of our customer base,
                will be used for market research or marketing efforts. Such
                information may include, but is not limited to, interests based
                on your cookies.
              </div>
              <div class="space-y-5">
                <div>
                  Personal Data that may be considering identifying may be used
                  for the following:
                </div>
                <div class="pl-0 space-y-5 md:pl-10">
                  <div>a) Improving your personal user experience</div>
                  <div>
                    b) Communicating with you about your user account with us
                  </div>
                  <div>
                    c) Marketing and advertising to you, including via email
                  </div>
                  <div>d) Fulfilling your purchases</div>
                  <div>e) Providing customer service to you</div>
                  <div>
                    f) Advising you about updates to the Mobile App or related
                    Items
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full font-mulish ">
            <div className="pb-2 font-semibold">
              <StyledH5Heading>
                Article 11 - DISCLOSURE OF PERSONAL DATA:
              </StyledH5Heading>
            </div>
            <div class="space-y-5 text-dark-gray">
              <div>
                Although our policy is to maintain the privacy of your Personal
                Data as described herein, we may disclose your Personal Data if
                we believe that it is reasonable to do so in certain cases, in
                our sole and exclusive discretion. Such cases may include, but
                are not limited to:
              </div>
              <div class="pl-0 space-y-5 md:pl-10">
                <div>
                  a) To satisfy any local, state, or Federal laws or regulations
                </div>
                <div>
                  b) To respond to requests, such discovery, criminal, civil, or
                  administrative process, subpoenas, court orders, or writs from
                  law enforcement or other governmental or legal bodies
                </div>
                <div>
                  c) To bring legal action against a user who has violated the
                  law or violated the terms of use of our Mobile App
                </div>
                <div>
                  d) As may be necessary for the operation of our Mobile App
                </div>
                <div>
                  e) To generally cooperate with any lawful investigation about
                  our users
                </div>
                <div>
                  f) If we suspect any fraudulent activity on our Mobile App or
                  if we have noticed any activity which may violate our terms or
                  other applicable rules
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full font-mulish ">
            <div className="pb-2 font-semibold">
              <StyledH5Heading>
                Article 12 - PUBLIC INFORMATION:
              </StyledH5Heading>
            </div>
            <div class="space-y-5 text-dark-gray">
              <div>
                We may allow users to post their own content or information
                publicly on our Mobile App. Such content or information may
                include, but is not limited to, photographs, status updates,
                blogs, articles, or other personal snippets. Please be aware
                that any such information or content that you may post should be
                considered entirely public and that we do not purport to
                maintain the privacy of such public information.
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full font-mulish ">
            <div className="pb-2 font-semibold">
              <StyledH5Heading>
                Article 13 - OPTING OUT OF TRANSMITTALS FROM US:
              </StyledH5Heading>
            </div>
            <div class="space-y-5 text-dark-gray">
              <div>
                From time to time, we may send you informational or marketing
                communications related to our Mobile App such as announcements
                or other information. If you wish to opt-out of such
                communications, you may contact the following email:
                <a className="text-[#0035b1d5] hover:text-[#6466dd] underline" href="mailto:info@lawnolu.com"> info@lawnolu.com.</a> You may also click the opt-out link which will
                be provided at the bottom of any and all such communications.
              </div>
              <div>
                Please be advised that even though you may opt-out of such
                communications, you may still receive information from us that
                is specifically about your use of our Mobile App or about your
                account with us.
              </div>
              <div>
                By providing any Personal Data to us, or by using our Mobile App
                in any manner, you have created a commercial relationship with
                us. As such, you agree that any email sent from us or
                third-party affiliates, even unsolicited email, shall
                specifically not be considered SPAM, as that term is legally
                defined.
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full font-mulish ">
            <div className="pb-2 font-semibold">
              <StyledH5Heading>
                Article 14 - MODIFYING, DELETING, AND ACCESSING YOUR
                INFORMATION:
              </StyledH5Heading>
            </div>
            <div class="space-y-5 text-dark-gray">
              <div>
                If you wish to modify or delete any information we may have
                about you, or you wish to simply access any information we have
                about you, you may do so from your account settings page.
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full font-mulish ">
            <div className="pb-2 font-semibold">
              <StyledH5Heading>
                Article 15 - ACCEPTANCE OF RISK:
              </StyledH5Heading>
            </div>
            <div class="space-y-5 text-dark-gray">
              <div>
                By continuing to our Mobile App in any manner, use the Product,
                you manifest your continuing asset to this Privacy Policy. You
                further acknowledge, agree and accept that no transmission of
                information or data via the internet is not always completely
                secure, no matter what steps are taken. You acknowledge, agree
                and accept that we do not guarantee or warrant the security of
                any information that you provide to us, and that you transmit
                such information at your own risk.
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full font-mulish ">
            <div className="pb-2 font-semibold">
              <StyledH5Heading>Article 16 - YOUR RIGHTS:</StyledH5Heading>
            </div>
            <div class="space-y-5 text-dark-gray">
              <div>
                You have many rights in relation to your Personal Data.
                Specifically, your rights are as follows:
              </div>
              <div class="pl-0 space-y-5 md:pl-10">
                <div>
                  - The right to be informed about the processing of your
                  Personal Data
                </div>
                <div>- The right to have access to your Personal Data</div>
                <div>
                  - The right to update and/or correct your Personal Data
                </div>
                <div>- The right to portability of your Personal Data</div>
                <div>
                  - The right to oppose or limit the processing of your Personal
                  Data
                </div>
                <div>
                  - The right to request that we stop processing and delete your
                  Personal Data
                </div>
                <div>
                  - The right to block any Personal Data processing in violation
                  of any applicable law
                </div>
                <div>
                  - The right to launch a complaint with the Federal Trade
                  Commission (FTC) in the United States or applicable data
                  protection authority in another jurisdiction.
                </div>
                <div>
                  Such rights can all be exercised by contacting us at the
                  relevant contact information listed in this Privacy Policy.
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full font-mulish ">
            <div className="pb-2 font-semibold">
              <StyledH5Heading>
                Article 17 - CONTACT INFORMATION:
              </StyledH5Heading>
            </div>
            <div class="space-y-5 text-dark-gray">
              If you have any questions about this Privacy Policy or the way we
              collect information from you, or if you would like to launch a
              complaint about anything related to this Privacy Policy, you may
              contact us at the following email address: <a className="text-[#0035b1d5] hover:text-[#6466dd] underline" href="mailto:info@lawnolu.com"> info@lawnolu.com.</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
