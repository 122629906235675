import React from "react";

const CustomStyledH2Heading = ({
  children,
  children2,
  fontColor,
  fontColor2,
  breakline
}) => {
  return (
    <h2 className={`text-h2 font-bricolage leading-none   ${fontColor}`}>
      {children}
      {breakline && <br />} 
      <span className={`${fontColor2}`}>{children2}</span>
    </h2>
  );
};

export default CustomStyledH2Heading;
